<script setup lang="ts">
import {ref, watch} from 'vue'

const props = defineProps({
  items: Array,
  active: String
})

const isOpen: any = ref(null)
const isOpensub: any = ref(null)
const checkOpen = (idItem: number) => {
  if (isOpen.value === null) {
    isOpen.value = idItem
  } else if (isOpen.value === idItem) {
    isOpen.value = null
    isOpensub.value = null
  } else {
    isOpen.value = idItem
  }
}
const statusActiveParent = ref()

const activeParent = (idLink: any) => {
  if (props.active === idLink) {
    checkActive(idLink)
  }
  return statusActiveParent.value === idLink ? 'lg:!text-orange-500' : ''
}
const activeChild = (idLink: any) => {
  if (props.active === idLink) {
    document.querySelectorAll('.subheadings .title-toc-js').forEach((item: any) => {
      item.classList.remove('lg:!text-orange-500')
    })
    document.querySelectorAll('.subheadings .icon-arrow-title-toc-js').forEach((item: any) => {
      item.classList.remove('lg:!text-orange-500')
    })
    document.querySelector(`a[href="#${idLink}"`)?.classList.add('lg:!text-orange-500')
  }
}
const checkActive = (idItem: any) => {
  statusActiveParent.value = idItem
}
</script>

<template>
  <ul class="space-y-3">
    <li v-for="(item, index) in items">
      <div>
        <div class="flex justify-between gap-1px">
          <a
              :href="`#${item.id}`"
              :class="[activeParent(item.id), active === item.id ? activeChild(item.id) : '']"
              class="title-toc-js font-semibold group-[.subheadings]:font-normal text-black/30 hover:text-orange-500 hover:underline underline-offset-4 text-15px xl:text-base"
          >
            <span class="">{{ item.number }}.</span>
            <span class=""> {{ ' ' + item.content }}</span>
          </a>
          <span
              v-if="item.subheadings.length"
              :class="[
                            isOpen === item.id || isOpensub === item.id ? 'rotate-90' : 'rotate-0',
                            activeParent(item.id),
                            active === item.id ? '!text-oganre-500' : ''
                        ]"
              class="icon-arrow-title-toc-js transform i-ic:sharp-chevron-right text-lg mt-0.5 text-bw-11/80 duration-200 cursor-pointer flex-none"
              @click="checkOpen(item.id)"
          ></span>
        </div>
        <transition name="slide">
          <TocList
              class="mt-3 ml-5 group subheadings"
              v-show="item.subheadings.length && isOpen === item.id"
              :items="item.subheadings"
              :active="active"
          />
        </transition>
        <div
            :class="+index + 1 <= items.length - 1 ? 'block' : 'hidden'"
            class="h-1px w-full bg-bw-20/40 my-3 xl:my-5 group-[.subheadings]:hidden"
        ></div>
      </div>
    </li>
  </ul>
</template>
<style scoped>
.slide-enter-active {
  transform-origin: top;
  transition: transform 0.2s ease;
  overflow: hidden;
}

.slide-leave-active {
  transform-origin: top;
  transition: transform 0.1s ease-in;
  overflow: hidden;
}

.slide-enter-from,
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}

.title-toc-js.activeFAQ {
  @apply lg:text-primary;
}

.title-toc-js.activeFAQ + .icon-arrow-title-toc-js {
  @apply !lg:text-primary;
}
</style>
<!-- || (props.active === item.id ? (isOpensub = item.id) : '') ||
isOpensub === item.id -->
